<template>
  <div class="active" ref="contentRef">
    <my-header type="search" mtype="activity" v-model="text" @search="search"></my-header>
    <div class="f26 c333 pt40 text-left">我的活动报名</div>
    <div class="list mt30 pt10" ref="container">
      <div class="item flex row-between" v-for="(item,index) in list" :key="index">
        <div class="ros flex text-left">
          <div class="img"><img :src="item.pic" ></div>
          <div class="dContent pl25"  >
            <div class="dTitle f20">{{item.name}}</div>
            <div class="des f16 c666 mtb15">{{fontNumber(item.abstract)}}<span class="more" @click="goDetail(item)">[查看详细]</span> </div>
            <div class="gotime flex">
              <div class="btime f14 pr15"><img src="../../assets/img/img16.png" >报名时间：{{item.bmStartDate}} - {{item.bmEndDate}}</div>
              <div class="btime f14"><img src="../../assets/img/img17.png" >活动时间：{{item.startDate}} - {{item.endDate}}</div>
            </div>
          </div>
        </div>
        <div class="sobtne">
          <div class="itemBtn color1 f18 mb20">{{item.joinQty}}人已报名</div>
          <div class="itemBtn color2 f18 cup" @click="cancelJoin(item)">取消报名</div>
        </div>
      </div>
      <div class="noMsg" v-if="!list.length && noMsgShow">
        <div class="img"><img src="../../assets/img/courseMsg.jpg" ></div>
        <div class="text-center f24 c333 pt10">查询无结果</div>
      </div>
    </div>
    
    
  </div>
</template>

<script>
import myHeader from '../../components/head.vue';
import { Scroll } from '@/utils/loading'
export default {
  name: 'activity',
  components: {
    myHeader
  },
  data(){
    return{
      text: '',
      list: [],
      page: 1,
      length: 0,
      clientWidth: 0,
      noMsgShow: false,
      loading: false,
    }
  },
  mounted() {
    this.getData();
    // 下拉加载 
    this.$scroll(this.$refs.container, () => {
      if (this.length > this.list.length) {
        !this.loading && this.getData()
      }
    })
    Scroll.listenTouchDirection()
  },
  methods:{
    async getData(){
      this.loading = true
      var params = {
        page: this.page,
        pagesize: 10,
        keyword: this.text
      }
      var res = await this.$api.get('/api/project_camp/joinList', params);
      if(res.code == 0){
        var data = res.data.list;
        if(data.length){
          data.forEach(item => {
            item.bmStartDate = item.bmStartDate.replace(/-/g, '.');
            item.bmEndDate = item.bmEndDate.replace(/-/g, '.');
            item.startDate = item.startDate.replace(/-/g, '.');
            item.endDate = item.endDate.replace(/-/g, '.');
          })
        }
        this.list = [...this.list, ...data];
        this.length = res.data.count;
      }
      this.page = this.page + 1;
      this.noMsgShow = true;
      this.loading = false
    },
    // 取消报名
    cancelJoin(item){
      var that = this;
      this.$confirm('是否确定取消报名?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        var res = await that.$api.post('/api/project_camp/cancelJoin', {id: item.id});
        if(res.code == 0){
          that.page = 1;
          that.list = [];
          await that.getData();
          that.$message.success('取消成功');
          //初始化当前用户报名了多少个活动
          that.$store.dispatch('setNumModal');
        }
      }).catch(() => {
                  
      });
    },
    // 开始搜索
    search(){
      if(!this.text) return this.$message.warning('关键词不能为空');
      this.list = [];
      this.page = 1;
      this.length = 0;
      this.getData();
    },
    goDetail(item){
      this.$router.push('/home/detail?id='+item.id)
    },
    // 字数截取
    fontNumber(val) {
      let winW = document.documentElement.clientWidth
      if(winW <= 1280) {
        return val.substring(0,55) + '...'
      }
      if(winW > 1280 && winW <= 1480) {
        return val.substring(0,58) + '...'
      }
      if(winW > 1480 && winW <= 1680) {
        return val.substring(0,75) + '...'
      }
      if(winW > 1680 && winW <= 1920 || winW > 1920) {
        return val.substring(0,85) + '...'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .active{
    height: 100%;
    padding: 50px 40px;
    .listBox{
      max-height: calc(100% - 100px);
      min-height: calc(100% - 100px);
      overflow:hidden;
      position: relative;
      overflow-y: auto;
    }
    .list{
      height: calc(100% - 140px);
      position: relative;
      overflow: auto;
      .item{
        width: 98%;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
        margin: 0 auto;
        margin-bottom: 26px;
        .sobtne{
          // height: 50px;
          .itemBtn{
            width: 145px;
            height: 50px;
            line-height: 50px;
            border-radius: 50px;
            border: 1px solid;
            &:first-child{
              margin-right: 20px;
            }
            &.color2{
              color: #999999;
              border-color: #999999;
            }
            &.color1{
              color: #272a2d;
              border-color: #6672fb;
            }
          }
        }
        .ros{
          flex: 1;
          .dContent{
            width: 70%;
            .gotime{
              .btime{
                color: #000000;
                img{
                  vertical-align: middle;
                  margin-right: 10px;
                  width: 18px;
                }
              }
            }
            .des{
              line-height: 24px;
              .more{
                color: #6e79fa;
                cursor: pointer;
              }
            }
            .dTitle{
              color: #272a2d;
              line-height: 1;
              overflow: hidden;
              white-space: nowrap;
              text-overflow:ellipsis;
            }
          }
          .img{
            width: 192px;
            height: 147px;
            border-radius: 14px;
            overflow: hidden;
            position: relative;
            img{
              width: 100%;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
</style>
